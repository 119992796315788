import { useMutation } from '@tanstack/react-query';
import { BillingData } from '../../types/BillingData';
import { createUserBillingData } from '../../api/createUserBillingData';
import { useParams } from 'react-router-dom';
import { useOrderInfo } from '../useOrderInfo';
import { useGetUserInfo } from '../useGetUserInfo';

const useCreateUserBillingData = () => {
  const { data: userData } = useGetUserInfo();
  const { orderId } = useParams() as {
    orderId: string;
  };
  const { data: orderInfo } = useOrderInfo(orderId);
  const paymentType = orderInfo?.paymentType as string;
  const shopCode = orderInfo?.shopCode as string;
  const email = userData?.email as string;

  return useMutation({
    mutationKey: ['createBillingData'],
    mutationFn: (data: Partial<BillingData>) =>
      createUserBillingData(userData?.id as number, { ...data, shopCode, paymentType, email }, orderId as string),
  });
};

export default useCreateUserBillingData;

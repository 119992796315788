import { OrderListItem } from '../types/OrderListItem';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';

type Attrs = {
  userId: string | number;
  currentPage: number;
};
type PaginationInfo = {
  pageCount: number;
  currentPage: number;
  itemsCount: number;
  pageSize: number;
};

const PAGINATION_HEADER = 'x-pagination';

export async function getOrderList({ currentPage, userId }: Attrs) {
  const endpoint = getEndpoint('orders/consumer/headers', {});
  const response = await api.raw<OrderListItem[]>(endpoint, {
    params: {
      userId,
      currentPage,
      orderStates: 'OnWorking,Shipped,Delivered,Cancelled',
      softwareCodes: 'PRINTUP_ANDROID,PRINTUP_IPHONE,PRINTUP_IPAD,MIOFOTOGRAFO',
    },
  });

  const paginationInfoHeader = response.headers.get(PAGINATION_HEADER);

  const paginationData: PaginationInfo = paginationInfoHeader
    ? JSON.parse(paginationInfoHeader)
    : { pageCount: 1, currentPage: 1, itemsCount: 0, pageSize: 0 };

  return {
    items: response._data || [],
    pageCount: paginationData.pageCount,
    itemsCount: paginationData.itemsCount,
  };
}

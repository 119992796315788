import { Button, Dialog, DialogContent, DialogHeader, DialogTitle, Typography } from '@photosi/web-design-system';
import ThumbUp from '../../assets/thumb-up.svg';
import { useTranslation } from 'react-i18next';
import { Image } from '../Image';

const EditShippingAddressSuccess: React.FC<{ isOpen: boolean; setIsOpen: (open: boolean) => void }> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation('editShippingAddress');

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="pds-text-center pds-flex pds-flex-col pds-items-center" data-testid="success-modal">
        <DialogHeader>
          <DialogTitle asChild>
            <Typography variant="heading2XL">{t('addressUpdateSuccess')}</Typography>
          </DialogTitle>
        </DialogHeader>
        <Image className="pds-h-32 pds-w-32 pds-my-4" alt="thumb up" src={ThumbUp} />
        <Button size="md" onClick={() => setIsOpen(false)}>
          {t('continue')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditShippingAddressSuccess;

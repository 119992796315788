import { Outlet } from 'react-router-dom';
import { Login } from '../../components/Login';
import { Loader } from '../../components/Loader';
import { useGetUserInfo } from '../../hooks';

export const PrivateRoutes: React.FC = () => {
  const { isError, isLoading } = useGetUserInfo();

  if (isError) {
    return <Login />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return <Outlet />;
};

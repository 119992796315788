import React, { useEffect } from 'react';
import login from '../../Auth';

const Login: React.FC = () => {
  useEffect(() => {
    login();
  }, []);

  return null;
};

export default Login;

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useGetUserInfo } from '../useGetUserInfo';
import { queries } from '../../queries/queries';

const PAGE_SEARCH_PARAM = 'page';

export function useOrderList() {
  const [params, setParams] = useSearchParams();
  const { data: userInfo } = useGetUserInfo();
  const currentPage = Number(params.get(PAGE_SEARCH_PARAM)) || 1;

  const { data, isError, isLoading } = useQuery({
    ...queries.orders.list({ currentPage: currentPage, userId: userInfo?.id as number }),
    retry: false,
    enabled: !!userInfo?.id,
  });

  const onPageChange = useCallback(
    (page: number) => {
      setParams({ [PAGE_SEARCH_PARAM]: page.toString() });
    },
    [setParams],
  );

  return {
    data,
    isError,
    isLoading,
    onPageChange,
    currentPage,
  };
}

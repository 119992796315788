import { endpointMap } from "../settings";

/**
 * Extracts parameter names from a URL template string.
 * @template T - The URL template string type
 * @example
 * type Params = ExtractParams<"/api/users/:id/posts/:postId"> // "id" | "postId"
 */
type ExtractParams<T extends string> = T extends `${string}:${infer Param}/${infer Rest}` ? Param | ExtractParams<Rest> : T extends `${string}:${infer Param}` ? Param : never;

/**
 * Creates a type-safe params object type based on URL parameters.
 * @template T - The URL template string type
 * @example
 * type Params = ParamsFromUrl<"/api/users/:id"> // { id: string } | undefined
 */
type ParamsFromUrl<T extends string> = T extends `${string}:${string}` ? { [K in ExtractParams<T>]: string } : never;

/**
 * Replaces URL template parameters with actual values in a type-safe way.
 * @template K - The key type from endpointMap
 * @template T - The endpoint URL template type
 * @param {T} url - The URL template with parameters (e.g. "/api/users/:id")
 * @param {ParamsFromUrl<T>} [params] - Object containing parameter values
 * @returns {string} The URL with parameters replaced
 * @example
 * // Static URL
 * getEndpointWithParams("/api/users") // "/api/users"
 * 
 * // With parameters
 * getEndpointWithParams("/api/users/:id", { id: "123" }) // "/api/users/123"
 * 
 * // With multiple parameters
 * getEndpointWithParams("/api/users/:userId/posts/:postId", {
 *   userId: "123",
 *   postId: "456"
 * }) // "/api/users/123/posts/456"
 */
export function getEndpoint<K extends keyof typeof endpointMap, T extends (typeof endpointMap[K])>(url: T, params: T extends `${string}:${string}` ? ParamsFromUrl<T> : Record<string, never>): string {
  let endpoint = url;
  if (params) {
    for (const [key, value] of Object.entries(params)) {
    // @ts-expect-error
      endpoint = endpoint.replace(`:${key}`, value);
    }
  }
  return endpoint;
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateShippingAddress, UpdateShippingAddressPayload } from '../../api/updateShippingAddress';
import { queries } from '../../queries/queries';

export const useUpdateShippingAddress = (orderId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateShippingAddressPayload) => updateShippingAddress(payload),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queries.orders.info(orderId).queryKey }),
  });
};

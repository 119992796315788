import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getCountryList } from '../api/getCountryList';
import { getAreaList } from '../api/getAreaList';

export const geographic = createQueryKeys('geographic', {
  countries: {
    queryFn: getCountryList,
    queryKey: null,
  },
  areas: (country: Parameters<typeof getAreaList>[0]) => ({
    queryFn: () => getAreaList(country),
    queryKey: [country ?? undefined],
  }),
});

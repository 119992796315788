import { useQuery } from '@tanstack/react-query';
import { isConsumer, isRetailer } from '../../utils/userInfoUtils';
import { queries } from '../../queries/queries';

export function useGetUserTypes() {
  return useQuery({
    ...queries.users.types,
    select: data => ({ ...data, isUserConsumer: isConsumer(data.types), isUserRetailer: isRetailer(data.types) }),
  });
}

import { useQuery } from '@tanstack/react-query';
import { getUserBillingData } from '../../api/getUserBillingData';
import { useGetUserTypes } from '../useGetUserTypes';
import { APIError } from '../../types/APIError';

type useGetUserBillingDataAttributes = {
  enabled?: boolean;
};

export const useGetUserBillingData = (options?: useGetUserBillingDataAttributes) => {
  const { data: userData } = useGetUserTypes();

  const { data, isError, error, isSuccess, ...rest } = useQuery({
    queryKey: ['billingData'],
    queryFn: () => getUserBillingData(userData?.id as number),
    refetchOnWindowFocus: false,
    enabled: options?.enabled,
    retry: 0,
  });

  // this const is required 'cause the API returns 404 when there's no data for that user
  const is404Error = (error as APIError)?.status === 404;

  return { data, isError, error, isSuccess, is404Error, ...rest };
};

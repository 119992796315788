import { useMutation } from '@tanstack/react-query';
import { updateUserBillingData } from '../../api/updateUserBillingData';
import { BillingData } from '../../types/BillingData';
import { useParams } from 'react-router-dom';
import { useOrderInfo } from '../useOrderInfo';
import { useGetUserInfo } from '../useGetUserInfo';

export const useUpdateUserBillingData = () => {
  const { data: userInfo } = useGetUserInfo();
  const { orderId } = useParams() as {
    orderId: string;
  };

  const { data: orderInfo } = useOrderInfo(orderId);

  const paymentType = orderInfo?.paymentType || '';
  const email = userInfo?.email || '';

  return useMutation({
    mutationKey: ['updateBillingData'],
    mutationFn: (data: Partial<BillingData>) =>
      updateUserBillingData(userInfo!.id, { ...data, paymentType, email }, orderId),
  });
};

import { useTranslation } from 'react-i18next';
import { Order } from '../../types/Order';
import { Typography } from '@photosi/web-design-system';

type Props = Pick<
  Order,
  | 'formattedPrice'
  | 'voucherValue'
  | 'couponCodes'
  | 'formattedVoucherValue'
  | 'formattedVat'
  | 'vat'
  | 'shippingCost'
  | 'formattedShippingCost'
  | 'formattedFixedCost'
  | 'formattedPaymentCost'
  | 'formattedCashOnDeliveryCost'
>;

const PriceDetails: React.FC<Props> = data => {
  const { t } = useTranslation('orderDetail');

  return (
    <div>
      <Typography variant="headingXL" className="pds-hidden sm:pds-block">
        {t('costDetails')}
      </Typography>
      <Typography variant="headingL" className="sm:pds-hidden pds-block">
        {t('costDetails')}
      </Typography>
      <div className="pds-space-y-2 pds-text-sm md:pds-text-base pds-mt-4">
        <div className="pds-flex pds-justify-between">
          <span>{t('subtotal')}</span>
          <span>{data?.formattedPrice}</span>
        </div>
        {!!data?.voucherValue && (
          <div className="pds-flex pds-justify-between" data-testid="coupon-code-container">
            <span>
              {t('voucherCode')}: <br />
              {data?.couponCodes && <span className="pds-text-secondary">{data?.couponCodes}</span>}
            </span>
            <span>{`-${data?.formattedVoucherValue}`}</span>
          </div>
        )}
        {!!data?.vat && (
          <div className="pds-flex pds-justify-between" data-testid="vat-container">
            <span>{t('vat')}</span>
            <span>{data?.formattedVat}</span>
          </div>
        )}
        <div className="pds-flex pds-justify-between">
          <span>{t('shipping')}</span>
          <span data-testid="shipping-cost-text">
            {data?.shippingCost ? data?.formattedShippingCost : data?.formattedFixedCost}
          </span>
        </div>
        {!!data?.formattedCashOnDeliveryCost && (
          <div className="pds-flex pds-justify-between">
            <span>{t('cashOnDeliveryCost')}</span>
            <span data-testid="cashOnDelivery-cost-text">{data?.formattedCashOnDeliveryCost}</span>
          </div>
        )}
        <div className="pds-flex pds-justify-between pds-font-bold">
          <span>{t('total')}</span>
          <span>{data?.formattedPaymentCost}</span>
        </div>
      </div>
    </div>
  );
};

export default PriceDetails;

import { formatInvoiceFields } from '../components/InvoiceForm/utils/formatInvoiceFields';
import { BillingData } from '../types/BillingData';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';
import { createInvoiceOrder } from './createInvoiceOrder';

type Payload = Partial<BillingData> & {
  paymentType: string;
  email: string;
};

export const updateUserBillingData = async (id: number, data: Payload, orderNumber: string) => {
  const nextData = formatInvoiceFields(data);
  const endpoint = getEndpoint('accounts/consumer-users/:id/billing-data', { id: id.toString() });
  await api(endpoint, {
    method: 'PATCH',
    body: nextData,
  });

  await createInvoiceOrder(orderNumber);
};

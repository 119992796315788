import { useQuery } from '@tanstack/react-query';
import OrderStateEnum from '../../types/OrderState';
import { INVOICESTATE } from '../../components/PaymentDetails/utils/const';
import { isOrderShippedAndOverdue } from '../../utils';
import { queries } from '../../queries/queries';

export function useOrderInfo(orderId: string) {
  const { data, isSuccess, isLoading, isError, refetch } = useQuery(queries.orders.info(orderId));

  const showReceiptButton =
    data?.invoiceState === INVOICESTATE.REQUESTABLE || data?.invoiceState === INVOICESTATE.NOT_REQUESTABLE_FR_AVAILABLE;
  const isInvoiceInProgress =
    data?.invoiceState === INVOICESTATE.IN_PROGRESS || data?.invoiceState === INVOICESTATE.WAITING_FOR_SHOPCODE;
  const isInvoiceRequestable = data?.invoiceState === INVOICESTATE.REQUESTABLE;
  const isInvoiceAvailable = data?.invoiceState === INVOICESTATE.AVAILABLE;
  const isInvoiceNotRequestable = data?.invoiceState === INVOICESTATE.NOT_REQUESTABLE;
  const overdueData = {
    orderState: data?.orderState,
    shippingType: data?.shippingType,
    isDeliveryDelayed: data?.isDeliveryDelayed,
  };

  const checkIsOrderShippedAndOverdue = isOrderShippedAndOverdue(overdueData);

  return {
    data,
    isOrderCanceled: data?.orderState === OrderStateEnum.Cancelled,
    isSuccess,
    isLoading,
    isError,
    refetch,
    showReceiptButton,
    isInvoiceInProgress,
    isInvoiceRequestable,
    isInvoiceAvailable,
    isInvoiceNotRequestable,
    checkIsOrderShippedAndOverdue,
  };
}

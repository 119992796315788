import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getFiscalReceiptUrl } from '../api/getFiscalReceiptUrl';
import { getInvoiceUrl } from '../api/getInvoiceUrl';

export const documents = createQueryKeys('documents', {
  receipt: (orderId: Parameters<typeof getFiscalReceiptUrl>[0]) => ({
    queryFn: () => getFiscalReceiptUrl(orderId),
    queryKey: [orderId],
  }),
  invoice: (orderId: Parameters<typeof getInvoiceUrl>[0]) => ({
    queryFn: () => getInvoiceUrl(orderId),
    queryKey: [orderId],
  }),
});

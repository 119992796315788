import { Typography } from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { Order } from '../../types/Order';
import { isDelivered, isMailOrder, isPickUp } from '../../utils';
import { EditShippingAddressModal } from '../EditShippingAddressModal';
import OrderStateEnum from '../../types/OrderState';

type Props = {
  order?: Order;
};

const ShippingDetails: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('orderDetail');

  const isShippingAddressEditable =
    isMailOrder(order?.shippingType) &&
    !isDelivered(order?.orderState) &&
    order?.shippingAddressEditable &&
    order.orderState !== OrderStateEnum.Cancelled;

  const shipmentRecipient = isPickUp(order?.shippingType)
    ? order?.shippingAddressCompanyName
    : `${order?.shippingAddressFirstName} ${order?.shippingAddressLastName}`;

  return (
    <div>
      <Typography variant="headingXL" className="pds-hidden sm:pds-block">
        {t('shippingInfo')}
      </Typography>
      <Typography variant="headingL" className="sm:pds-hidden pds-block">
        {t('shippingInfo')}
      </Typography>
      <div className="pds-text-sm md:pds-text-base md:pds-max-w-80 pds-leading-5 pds-mt-4">
        <div>
          {shipmentRecipient}
          {order?.shippingAddressCareOf && (
            <span>
              , {t('shippingAddressCareOf')} {order?.shippingAddressCareOf}
            </span>
          )}
        </div>
        <div>
          {order?.shippingAddressStreet} {order?.shippingAddressNumber}
        </div>
        <div>
          {order?.shippingAddressZipCode} {order?.shippingAddressCity} ({order?.shippingAddressArea})
        </div>
        <div>{order?.shippingAddressCountry}</div>
        {order?.shippingAddressAdditionalInfo && (
          <div>
            {t('shippingAdditionalInfo')}: {order?.shippingAddressAdditionalInfo}
          </div>
        )}
        {isShippingAddressEditable && <EditShippingAddressModal order={order} />}
      </div>
    </div>
  );
};

export default ShippingDetails;

import {
  Button,
  buttonVariants,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import genericError from '../../assets/dialog/generic-error.svg';
import orderConfirmed from '../../assets/dialog/order-confirmed.svg';
import { Image } from '../Image';

type DialogInfo = {
  image: string;
  title: string;
  description: string;
};

export type CancelOrderErrorCodes = 'TOO_LATE' | 'ALREADY_CANCELLED' | 'GENERIC_ERROR' | 'TIMER_EXPIRED';

const dialogError: Record<CancelOrderErrorCodes, DialogInfo> = {
  GENERIC_ERROR: {
    image: genericError,
    title: 'errorTitle',
    description: 'errorDescription',
  },
  TOO_LATE: {
    image: genericError,
    title: 'errorTitle',
    description: 'errorDescription',
  },
  TIMER_EXPIRED: {
    image: orderConfirmed,
    title: 'tooLateTitle',
    description: 'tooLateDescription',
  },
  ALREADY_CANCELLED: {
    image: orderConfirmed,
    title: 'tooLateTitle',
    description: 'tooLateDescription',
  },
};

type Props = {
  isOpened: boolean;
  code: CancelOrderErrorCodes;
  onRetry?: () => void;
};

const CancelOrderErrorDialog: React.FC<Props> = ({ isOpened, code, onRetry }) => {
  const { t } = useTranslation(['cancelOrder', 'orderList', 'common']);
  const { button } = buttonVariants();
  const { description, image, title } = dialogError[code] || dialogError.GENERIC_ERROR;

  return (
    <Dialog open={isOpened}>
      <DialogContent showCloseIcon={false} data-testid="error-dialog-content">
        <div className="pds-flex pds-justify-center pds-items-center pds-mb-4">
          <Image src={image} height="100px" width="100px" alt={t([`popup.${title}`])} />
        </div>
        <DialogHeader>
          <DialogTitle>{t([`popup.${title}`])}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{t([`popup.${description}`])}</DialogDescription>
        <div className="pds-mt-8">
          {code === 'GENERIC_ERROR' || code === 'TOO_LATE' ? (
            <Button size="md" className="pds-w-full" onClick={onRetry} data-testid="generic-error-button">
              {t('retry', { ns: 'common' })}
            </Button>
          ) : (
            <Link
              data-testid="error-link"
              className={button({
                variant: 'primary',
                size: 'md',
              })}
              to="/orders"
            >
              {t('popup.detail', { ns: 'cancelOrder' })}
            </Link>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CancelOrderErrorDialog;

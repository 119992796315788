import { ENABLED_SHIPPING_COUNTRIES } from '../settings';
import { Country } from '../types/Country';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';

export async function getCountryList() {
  const response = await api<Country[]>(getEndpoint('geographics/countries', {}));
  return response
    .filter(c => ENABLED_SHIPPING_COUNTRIES.includes(c.isoAlpha3))
    .map(c => ({
      code: c.isoAlpha3,
      name: c.name,
    }));
}

import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrderList } from '../../hooks/useOrderList';
import NoOrdersSvg from '../../assets/no-orders.svg';
import { Typography } from '@photosi/web-design-system';
import { Image } from '../../components/Image';
import { Loader } from '../../components/Loader';
import { Error } from '../../components/Error';
import { Pagination } from '../../components/Pagination';
import { RetailerBanner } from '../../components/RetailerBanner';
import { OrderListItem } from '../../components/OrderListItem';
import { Amplitude } from '../../utils/amplitude';
import { OrderList as OrderListType } from '../../types/OrderListItem';

const OrderList: React.FC = () => {
  const { t } = useTranslation('orderList');
  useEffect(() => {
    Amplitude.myOrderView();
  }, []);

  return (
    <div className="pds-flex pds-flex-col pds-items-center pds-w-full" data-testid="order-list-page">
      <Typography variant="heading3XL" className="pds-font-medium pds-mb-6 pds-text-center">
        {t('myOrders')}
      </Typography>
      <PageContent />
    </div>
  );
};

const NoOrders = () => {
  const { t } = useTranslation('orderList');

  return (
    <div className="pds-text-center" data-testid="no-orders-placeholder">
      <div>
        <Image alt="no orders" src={NoOrdersSvg} />
      </div>
      {t('noOrders')}
    </div>
  );
};

const PageContent = () => {
  const { data, currentPage, onPageChange, isError, isLoading } = useOrderList();
  if (isLoading) {
    return <Loader />;
  }

  if (isError)
    return (
      <>
        <Error />
        <div className="pds-mt-5 pds-mb-3">
          <Pagination currentPage={currentPage} pageCount={1} onPageChange={onPageChange} />
        </div>
      </>
    );

  if (data?.items?.length === 0) {
    return <NoOrders />;
  }

  return (
    <>
      <RetailerBanner />
      <ListItems items={data?.items || []} currentPage={currentPage} />
      <div className="pds-mt-5 pds-mb-3">
        <Pagination currentPage={currentPage} onPageChange={onPageChange} pageCount={data?.pageCount as number} />
      </div>
    </>
  );
};

const ListItems = memo(({ items = [], currentPage }: { items: OrderListType['items']; currentPage: number }) => {
  return (
    <div className="pds-space-y-4 pds-w-full">
      {items.map(order => (
        <OrderListItem key={order.guid} order={order} currentPage={currentPage} />
      ))}
    </div>
  );
});

export default OrderList;

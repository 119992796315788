import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getUserTypes } from '../api/getUserTypes';
import { getUserInfo } from '../api/getUserInfo';
import { getUserBillingData } from '../api/getUserBillingData';

export const users = createQueryKeys('users', {
  types: {
    queryFn: () => getUserTypes(),
    queryKey: null,
  },
  info: {
    queryFn: () => getUserInfo(),
    queryKey: null,
  },
  billingData: (userId: Parameters<typeof getUserBillingData>[0]) => ({
    queryFn: () => getUserBillingData(userId),
    queryKey: [userId],
  }),
});

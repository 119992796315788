import { formatInvoiceFields } from '../components/InvoiceForm/utils/formatInvoiceFields';
import { BillingData } from '../types/BillingData';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';
import { createInvoiceOrder } from './createInvoiceOrder';

type UpdatedData = Partial<BillingData> & {
  shopCode: string;
  paymentType: string;
  email: string;
};

export async function createUserBillingData(id: number, data: UpdatedData, orderNumber: string) {
  const nextData = formatInvoiceFields({ ...data });

  await api(getEndpoint(`accounts/consumer-users/:id/billing-data`, { id: id.toString() }), {
    method: 'POST',
    body: nextData,
  });

  return createInvoiceOrder(orderNumber);
}

import { CancelOrder } from '../types/CancelOrder';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';

type Attrs = {
  orderId: string;
  reason: string;
};

export function deleteHeaders({ orderId, reason }: Attrs) {
  return api<CancelOrder>(getEndpoint('orders/consumer/headers/:id', { id: orderId }), {
    method: 'DELETE',
    body: { reason },
  });
}

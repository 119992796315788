import { FetchError } from 'ofetch';
import { api } from './api';
import { getEndpoint } from '../utils/getEndpoint';

export function getFiscalReceiptUrl(orderId: string) {
  try {
    return api<{ url: string }>(getEndpoint(`orders/consumer/documents/fiscal-receipts/:orderId`, { orderId }));
  } catch (error: unknown) {
    if (error instanceof FetchError) {
      throw new Error(`${error.status}: ${error.statusText}`);
    }
    throw error;
  }
}

export const API_KEY = import.meta.env.VITE_APP_API_KEY;
export const API_ROOT = import.meta.env.VITE_APP_API_ROOT;
export const ENABLED_SHIPPING_COUNTRIES = ['ITA', 'ESP', 'VAT', 'SMR'];
export const LOGIN_URL = import.meta.env.VITE_APP_LOGIN_URL;
export const SENTRY_DSN = `${import.meta.env.VITE_APP_SENTRY_DSN}`;
export const GTM_ID = import.meta.env.VITE_APP_GTM_ID;
export const DATALAYER_NAME = 'dataLayer';
export const AVAILABLE_LANGUAGES: string[] = ['it', 'en', 'es', 'fr', 'de', 'nl'];
export const DEFAULT_LANGUAGE: string = AVAILABLE_LANGUAGES[0];
export const LANGUAGE_COOKIE: string = 'phs_choosen_language';
export const ADDRESS_FIRSTNAME_MAXLENGTH = 20;
export const ADDRESS_LASTNAME_MAXLENGTH = 20;
export const ADDRESS_STREET_MAXLENGTH = 30;
export const ADDRESS_NUMBER_MAXLENGTH = 10;
export const ADDRESS_ZIPCODE_MAXLENGTH = 9;
export const ADDRESS_CITY_MAXLENGTH = 30;
export const ADDRESS_PHONE_MAXLENGTH = 15;
export const ADDRESS_ADDITIONAL_INFO_MAXLENGHT = 30;
export const ADDRESS_CAREOF_MAXLENGHT = 25;
export const SUPPORT_FORM_LANGUAGE: Record<string, string> = {
  it: 'it',
  es: 'es',
};
export const SUPPORT_LOGIN_URL = 'https://support.photosi.com/access?return_to=';
export const SUPPORT_FORM_BASE_URL = 'https://support.photosi.com/hc/';
export const SUPPORT_FORM_REQUEST_URL = '/requests/new';
export const SUPPORT_URL = 'https://support.photosi.com/';
export const SUPPORT_FORM_BASE_URL_RETAIL = 'https://b2b-photosi.zendesk.com/hc/';
export const SUPPORT_LOGIN_URL_RETAIL = 'https://b2b-photosi.zendesk.com/access?return_to=';

export const DIDOMI_CONSENT_ID = import.meta.env.VITE_APP_DIDOMI_CONSENT_ID;
export const DIDOMI_API_KEY = import.meta.env.VITE_APP_DIDOMI_API_KEY;
export const DIDOMI_AMPLITUDE_CONSENT_ID = 'c:amplitude';
export const AMPLITUDE_API_KEY = import.meta.env.VITE_APP_AMPLITUDE_API_KEY;

export const HOME_URL = import.meta.env.VITE_APP_HOME_URL;
export const BRAZE_API_KEY: string = import.meta.env.VITE_BRAZE_API_KEY;
export const BRAZE_BASE_URL: string = import.meta.env.VITE_BRAZE_BASE_URL;
export const BRAZE_SERVICEWORKER_LOCATION: string = import.meta.env.VITE_BRAZE_SERVICEWORKER_LOCATION;

export const endpointMap = {
  'user-profile': 'accounts/consumer-users/current',
  'user-types': 'accounts/user-types/current',
  'user-billing': 'accounts/consumer-users/:id/billing-data',
  'order-list': 'orders/consumer/headers',
  'order-details': 'orders/consumer/headers/:id',
  'order-shipping-rows': 'orders/consumer/shipping-row-groups',
  'order-cancellation-reasons': 'orders/consumer/cancellation-reasons',
  'document-fiscal-receipt': 'orders/consumer/documents/fiscal-receipts/:orderId',
  'document-invoice': 'orders/consumer/documents/invoices/:orderId',
  'document-invoice-order': 'orders/consumer/invoices',
  'geographic-areas': 'geographics/areas',
  'geographic-countries': 'geographics/countries',
} as const;

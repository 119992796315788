import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getOrderList } from '../api/getOrderList';
import { getOrderInfo } from '../api/getOrderInfo';
import { getShippingRows } from '../api/getShippingRows';
import { getCancellationReasonList } from '../api/getCancellationReasonList';

export const orders = createQueryKeys('orders', {
  list: ({ currentPage, userId }: Parameters<typeof getOrderList>[0]) => ({
    queryFn: () => getOrderList({ currentPage, userId }),
    queryKey: [currentPage, userId],
  }),
  info: (orderId: Parameters<typeof getOrderInfo>[0]) => ({
    queryFn: () => getOrderInfo(orderId),
    queryKey: [orderId],
  }),
  shippingRows: (orderId: Parameters<typeof getShippingRows>[0]) => ({
    queryFn: () => getShippingRows(orderId),
    queryKey: [orderId],
  }),
  cancellationReason: {
    queryFn: getCancellationReasonList,
    queryKey: null,
  },
});

import { buttonVariants, Icon, Typography } from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { useGetUserTypes } from '../../hooks/useGetUserTypes';

const RetailerBanner: React.FC = () => {
  const { t } = useTranslation('orderList');
  const { button } = buttonVariants();
  const { data, isLoading, isError } = useGetUserTypes();

  if (isLoading || isError || !data?.isUserRetailer) {
    return null;
  }

  return (
    <div
      data-testid="retailer-banner"
      className="pds-w-full pds-mb-4 pds-bg-[#F5F4F6] pds-items-center pds-shadow-lg sm:pds-shadow-none pds-flex pds-flex-col md:pds-flex-row pds-justify-start md:pds-justify-between md:pds-rounded-3xl pds-rounded-3xl md:pds-py-4 lg:pds-gap-4 pds-gap-2 md:pds-px-6 pds-px-4 pds-py-4"
    >
      <div className="pds-flex sm:pds-items-center pds-gap-4 pds-w-full md:pds-w-4/5 pds-flex-col sm:pds-flex-row pds-items-start">
        <Icon name="shoppingBagOutline" className="pds-w-8 pds-h-8 pds-text-[#754AFA]" />
        <div className="pds-flex pds-flex-col pds-gap-1 pds-w-full">
          <Typography className="pds-font-bold">{t('retailBannerTitle')}</Typography>
          <Typography>{t('retailBannerDescription')}</Typography>
        </div>
      </div>
      <a
        className={button({
          variant: 'ghost',
          className: 'pds-self-start sm:pds-self-center sm:pds-justify-end pds-px-0 hover:pds-bg-transparent pds-w-fit',
        })}
        href="https://my.photosi.com/#/main/orders-retailer"
      >
        <Typography className="pds-font-bold pds-w-full">{t('retailBannerReservedArea')}</Typography>
        <Icon name="exitDoor" className="pds-w-6" />
      </a>
    </div>
  );
};

export default RetailerBanner;

import { ShippingAddress } from '../types/Address';
import { Order } from '../types/Order';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';

export type UpdateShippingAddressPayload = {
  orderGUID: string;
} & ShippingAddress;

export function updateShippingAddress({ orderGUID, ...shippingInfo }: UpdateShippingAddressPayload) {
  const endpoint = getEndpoint('orders/consumer/headers/:id', { id: orderGUID });
  return api<Order>(endpoint, {
    method: 'PUT',
    body: {
      shippingAddressFirstName: shippingInfo.firstName,
      shippingAddressLastName: shippingInfo.lastName,
      shippingAddressStreet: shippingInfo.street,
      shippingAddressNumber: shippingInfo.number,
      shippingAddressZipCode: shippingInfo.zipCode,
      shippingAddressCity: shippingInfo.city,
      shippingAddressArea: shippingInfo.area,
      shippingAddressCountry: shippingInfo.country,
      shippingAddressPhone: shippingInfo.phone,
      shippingAddressAdditionalInfo: shippingInfo.additionalInfo,
      shippingAddressCareOf: shippingInfo.careOf,
    },
  });
}

import { ofetch } from 'ofetch';
import { getUserToken, selectLanguage } from '../utils';
import { API_KEY, API_ROOT } from '../settings';

const defaultHeaders = {
  Accept: 'application/json',
  'accept-language': selectLanguage(),
  'x-api-key': `${API_KEY}`,
};

export const api = ofetch.create({
  baseURL: API_ROOT,
  onRequest: ({ options }) => {
    const token = getUserToken();
    if (token) {
      const headers = new Headers({ ...defaultHeaders, ...options.headers, authorization: `Bearer ${token}` });
      options.headers = headers;
    }
  },
});

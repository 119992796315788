import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrderListItem } from '../../types/OrderListItem';
import { Button, buttonVariants } from '@photosi/web-design-system';
import { Amplitude } from '../../utils/amplitude';

type Props = Pick<OrderListItem, 'photosiTrackingUrl' | 'shippingRowGroupsCount' | 'guid'> & { currentPage: number };

const TrackingButton: React.FC<Props> = ({ shippingRowGroupsCount, photosiTrackingUrl, guid, currentPage }) => {
  const { t } = useTranslation('orderList');
  const navigate = useNavigate();
  const { button } = buttonVariants();

  if (!photosiTrackingUrl) return null;

  // Show link to order details
  if (shippingRowGroupsCount > 1) {
    return (
      <Button
        className={button({
          size: 'md',
        })}
        data-testid="single-order-link"
        onClick={() => {
          navigate(`/orders/${guid}`, { state: { currentPage } });
          Amplitude.orderTrackClick({ type: 'detail', level: 'order' });
        }}
      >
        {t('trackShipping')}
      </Button>
    );
  }

  // Show link to tracking page
  return (
    <Button
      className={button({
        size: 'md',
      })}
      rel="noopener noreferrer"
      data-testid="tracking-link"
      onClick={() => {
        Amplitude.orderTrackClick({ level: 'order', type: 'track' });
        window.open(photosiTrackingUrl);
      }}
    >
      {t('trackShipping')}
    </Button>
  );
};

export default TrackingButton;

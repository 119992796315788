import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useShippingRows } from '../../hooks/useShippingRows';
import { ShippingRow } from '../ShippingRow';
import { Typography } from '@photosi/web-design-system';
import { Loader } from '../Loader';
import { Error } from '../Error';

const ShippingRows: React.FC = () => {
  const { orderId } = useParams() as {
    orderId: string;
  };
  const { isLoading, data, isError } = useShippingRows(orderId);
  const renderShippingRowList = useMemo(
    () => (data || []).map((shippingRow, index) => <ShippingRow key={`shippingRow_${index}`} data={shippingRow} />),
    [data],
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Typography className="pds-text-sm md:pds-text-base" data-testid="shipping-rows-container">
      {renderShippingRowList}
    </Typography>
  );
};

export default ShippingRows;

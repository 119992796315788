import { CancellationReason } from '../types/CancellationReson';
import { getEndpoint } from '../utils/getEndpoint';
import { api } from './api';

type Response = {
  items: CancellationReason[];
};
export function getCancellationReasonList() {
  return api<Response>(getEndpoint(`orders/consumer/cancellation-reasons`, {}));
}

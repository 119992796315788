import { useQuery } from '@tanstack/react-query';
import { queries } from '../../queries/queries';

export function useGetCountryList() {
  const { data, ...rest } = useQuery({
    ...queries.geographic.countries,
    select: data => ({
      countries: data,
      onlyItaliansCountries: data?.filter(country => country.code !== 'ESP'),
    }),
  });

  return {
    data,
    ...rest,
  };
}

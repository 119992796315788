import { useQuery } from '@tanstack/react-query';
import { APIError } from '../../types/APIError';
import { useParams } from 'react-router-dom';
import { queries } from '../../queries/queries';

export const useGetInvoiceUrl = () => {
  const { orderId } = useParams();

  const { error, ...rest } = useQuery({
    ...queries.documents.invoice(orderId as string),
    retry: 0,
  });

  // this const is required 'cause the API returns 404 where document's url is missing. It doesn't indicate that there's an error, but that there's no data
  const is404Error = (error as APIError)?.status === 404;

  return { error, is404Error, ...rest };
};

import { useMutation } from '@tanstack/react-query';
import { deleteHeaders } from '../../api/deleteHeaders';

type Payload = {
  reason: string;
  orderId: string;
};

export const useDeleteHeader = () => {
  return useMutation({ mutationFn: (payload: Payload) => deleteHeaders(payload) });
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { OrderState } from '../OrderState';
import { formatDate, formatPaymentType, isMailOrder, isOrderShippedAndOverdue } from '../../utils';
import { OrderListItem as OrderListItemType } from '../../types/OrderListItem';
import { OrderDeliveryDate } from '../OrderDeliveryDate';
import { CancelledRows } from '../CancelledRows';
import OrderStateEnum from '../../types/OrderState';
import { TrackingButton } from '../TrackingButton';
import { Button, buttonVariants, Icon, Typography } from '@photosi/web-design-system';
import { OrderListItemTimer } from '../OrderListItemTimer';
import { Amplitude } from '../../utils/amplitude';
import { SupportUrl } from '../SupportUrl';
import OrderStatusBanner from '../OrderStatusBanner/OrderStatusBanner';

type OrderListItemProps = {
  order: OrderListItemType;
  currentPage: number;
};

const OrderListItem: React.FC<OrderListItemProps> = ({ order, currentPage }) => {
  const { t } = useTranslation('orderList');
  const orderNumber = order.number.toString();
  const navigate = useNavigate();
  const showCancelledRows = order.cancelledRowsCount > 0 && order.orderState !== OrderStateEnum.Cancelled;
  const showCancelOrderBanner =
    order.orderState === OrderStateEnum.OnHold ||
    order.orderState === OrderStateEnum.OnWorking ||
    order.orderState === OrderStateEnum.Confirmed;

  const overdueData = {
    orderState: order.orderState,
    shippingType: order.shippingType,
    isDeliveryDelayed: order.isDeliveryDelayed,
  };

  const { button } = buttonVariants();

  return (
    <div
      className="pds-mb-4 pds-p-6 pds-border pds-border-gray-200 pds-border-b pds-rounded-3xl pds-w-full pds-shadow-lg"
      data-testid="order-list-item"
    >
      <div className="pds-border-b pds-pb-4 pds-border-400">
        <div className="pds-flex pds-flex-wrap pds-justify-between md:pds-space-y-0 sm:pds-space-y-4 xs:pds-space-y-4">
          <div className="xs:pds-w-full sm:pds-w-full md:pds-w-3/4">
            <div className="pds-mb-2 pds-flex pds-flex-col sm:pds-flex-row sm:pds-items-center pds-space-y-4 sm:pds-space-y-0 pds-items-start sm:pds-w-full sm:pds-space-x-5">
              <OrderState orderState={order.orderState} />
              {showCancelledRows && <CancelledRows count={order.cancelledRowsCount} />}
              <OrderDeliveryDate {...order} />
            </div>
          </div>
          <div className="md:pds-w-1/4 sm:pds-w-full xs:pds-w-full pds-hidden pds-self-center sm:pds-flex pds-flex-col pds-space-y-4">
            <SupportUrl orderNumber={order.number.toString()} />
          </div>
        </div>

        {isOrderShippedAndOverdue(overdueData) && (
          <OrderStatusBanner className="pds-my-3">
            <Typography className="pds-text-[#ff0000] sm:pds-text-left " data-testid="overdue-order-text">
              <span className="pds-font-bold">{t('shippingDelayed.title')}</span>
              <span> - {t('shippingDelayed.subtitle')}</span>
            </Typography>
          </OrderStatusBanner>
        )}
      </div>
      <div className="pds-pt-6">
        <div className="pds-flex pds-flex-wrap pds-justify-between xs:pds-space-y-4">
          <div className="md:pds-w-1/2 sm:pds-w-full xs:pds-w-full pds-flex pds-flex-wrap xs:pds-space-y-2 sm:pds-space-y-0 pds-content-center pds-space-y-2">
            <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
              <Typography className="xs:pds-flex-auto pds-w-full pds-font-semibold">{t('orderNumber')}:</Typography>
              <Typography className="xs:pds-flex-auto md:pds-flex pds-text-end md:pds-text-start md:pds-gap-2 sm:pds-text-orange-500 pds-font-normal md:pds-font-semibold">
                {orderNumber}
              </Typography>
            </div>
            <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
              <Typography className="xs:pds-flex-auto pds-font-semibold">{t('createdOn')}:</Typography>
              <Typography className="xs:pds-flex-auto">{formatDate(order.creationDate)}</Typography>
            </div>
            <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
              <Typography className="xs:pds-flex-auto pds-font-semibold">{t('payment')}:</Typography>
              <Typography className="xs:pds-flex-auto" data-testid="payment-type">
                {order.orderState === OrderStateEnum.Cancelled
                  ? t('payment:canceled')
                  : formatPaymentType(order.paymentType)}
              </Typography>
            </div>
            <div className="xs:pds-w-full pds-w-full xs:pds-flex sm:pds-w-1/2 sm:pds-mb-4 pds-flex sm:pds-flex-col pds-justify-between sm:pds-justify-normal pds-items-center sm:pds-items-start">
              <Typography className="xs:pds-flex-auto pds-font-semibold">{t('total')}:</Typography>
              <Typography className="xs:pds-flex-auto">{order.formattedPaymentCost}</Typography>
            </div>
          </div>
          {isMailOrder(order.shippingType) &&
            order.shippingAddressEditable &&
            order.orderState !== OrderStateEnum.Cancelled && (
              <div className="pds-mt-6 sm:pds-hidden pds-flex">
                <Link
                  className="pds-text-water-500 pds-flex pds-items-center pds-gap-4"
                  to={`/orders/${order.guid}/shipping-address`}
                  state={{ currentPage }}
                >
                  <Icon name="edit" className="pds-w-6 pds-h-6" />
                  <Typography className="pds-font-bold">{t('editShippingAddress')}</Typography>
                </Link>
              </div>
            )}
          <div className="pds-w-full pds-mt-6 pds-flex md:pds-hidden">
            {showCancelOrderBanner && (
              <OrderListItemTimer
                isCancellable={
                  order.orderState === OrderStateEnum.OnHold || order.orderState === OrderStateEnum.Confirmed
                }
                isOnWorking={order.orderState === OrderStateEnum.OnWorking}
                order={order}
              />
            )}
          </div>
          <div className="md:pds-w-2/6 lg:pds-w-1/4 sm:pds-w-full xs:pds-w-full pds-self-end pds-flex pds-flex-col pds-space-y-4 pds-mt-6 md:pds-mt-0 pds-w-full">
            {isMailOrder(order.shippingType) &&
              order.shippingAddressEditable &&
              order.orderState !== OrderStateEnum.Cancelled && (
                <div className="sm:pds-flex pds-justify-end pds-hidden">
                  <Link
                    className="pds-text-water-500 pds-flex pds-items-center pds-gap-4"
                    to={`/orders/${order.guid}/shipping-address`}
                    state={{ currentPage }}
                    data-testid="shipping-address-link"
                  >
                    <Icon name="edit" className="pds-w-6 pds-h-6" />
                    <Typography className="pds-font-bold">{t('editShippingAddress')}</Typography>
                  </Link>
                </div>
              )}
            <TrackingButton
              shippingRowGroupsCount={order.shippingRowGroupsCount}
              guid={order.guid}
              photosiTrackingUrl={order.photosiTrackingUrl}
              currentPage={currentPage}
            />
            <Button
              className={button({
                variant: 'outline',
                size: 'md',
              })}
              onClick={() => {
                Amplitude.orderDetailClick();
                navigate(`/orders/${order.guid}`, { state: { currentPage } });
              }}
            >
              {t('orderDetail')}
            </Button>
          </div>
          <div className="md:pds-w-1/3 sm:pds-w-full pds-mt-6 xs:pds-w-full sm:pds-hidden pds-self-center pds-flex pds-flex-col pds-space-y-4">
            <SupportUrl orderNumber={order.number.toString()} />
          </div>
          <div className="pds-w-full pds-mt-8 md:pds-flex pds-hidden">
            {showCancelOrderBanner && (
              <OrderListItemTimer
                isCancellable={
                  order.orderState === OrderStateEnum.OnHold || order.orderState === OrderStateEnum.Confirmed
                }
                isOnWorking={order.orderState === OrderStateEnum.OnWorking}
                order={order}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListItem;
